import React from "react";

import SiteLayout from "@layouts/site-layout";
import HeaderEnrollNow from "@components/ExploreMore/customComponents/HeaderEnrollNow";
import AppliedLearningProject from "@components/ExploreMore/AppliedLearningProject";
import ColorfulBox from "@components/ExploreMore/customComponents/ColorfulBox";
import BuildJob from "@components/ExploreMore/customComponents/BuildJob";
import Skills from "@components/ExploreMore/customComponents/Skills";
import Certificate from "@components/ExploreMore/customComponents/Certificate";
import WhatyouWillLearn from "@components/ExploreMore/customComponents/WhatyouWillLearn";

const data = {
  boxDetails: {
    title: "Web & Mobile Application Development",
    text:
      "Master the art of building web and mobile apps from scratch using the the MERN stack or the legacy JAVA stack",
    rating: "4.7/5",
    totalRating: "( 91,664 ratings )",
    enroll: "1,223,890 already enrolled",
    started: "Start from 15 april",
  },

  colorfulBoxContent: [
    {
      bgColor: "#FBFDD1",
      txtColor: "#7D7D7D",
      headerColor: "#545706",
      headerTxt: "3 Months",
      contentTxt: "Under 10 hours study of study a week.",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#7D7D7D",
      headerColor: "#0A662A",
      headerTxt: "English",
      contentTxt: "Under 10 hours study of study a week.",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#7D7D7D",
      headerColor: "#6F0755",
      headerTxt: "Beginner",
      contentTxt: "No prior experience required.",
    },
    {
      bgColor: "#DFD4FF",
      txtColor: "#7D7D7D",
      headerColor: "#2A0D7E",
      headerTxt: "100% Self-Paced",
      contentTxt: "No prior experience required.",
    },
  ],

  jobTitles: [
    { contentTxt: "Web Developer" },
    { contentTxt: "Android Developer" },
    { contentTxt: "Java Developer" },
    { contentTxt: "Frontend Developer" },
    { contentTxt: "Backend Developer" },
    { contentTxt: "SQL Admin" },
    { contentTxt: "Database Admin" },
    { contentTxt: "React Developer" },
  ],

  jobTitlesBox: [
    {
      bgColor: "#DFD4FF",
      txtColor: "#7D7D7D",
      headerColor: "#2A0D7E",
      headerTxt: "500,000",
      contentTxt: "in-demand Job Listings",
    },
    {
      bgColor: "#FBFDD1",
      txtColor: "#7D7D7D",
      headerColor: "#545706",
      headerTxt: "12 LPA+",
      contentTxt: "Median salary",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#7D7D7D",
      headerColor: "#0A662A",
      headerTxt: "100,000 TB",
      contentTxt: "of data transformed and analysed in 2022",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#7D7D7D",
      headerColor: "#6F0755",
      headerTxt: "14+",
      contentTxt: "Hands on projects.",
    },
  ],

  skills: [
    {
      bgColor: "#FBFDD1",
      txtColor: "#AE9510",
      contentTxt: "Web Development",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#28AF58",
      contentTxt: "Coding",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#ED77D0",
      contentTxt: "Application Development",
    },
    {
      bgColor: "#DFD4FF",
      txtColor: "#4564CB",
      contentTxt: "Frontend",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#28AF58",
      contentTxt: "Backend",
    },
    {
      bgColor: "#FBFDD1",
      txtColor: "#AE9510",
      contentTxt: "Python",
    },
    {
      bgColor: "#DFD4FF",
      txtColor: "#4564CB",
      contentTxt: "JAVA",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#ED77D0",
      contentTxt: "React",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#28AF58",
      contentTxt: "SQL",
    },
  ],

  aboutProfessionalCertificate: [
    "This course is designed for individuals who want to develop the skills necessary to create innovative solutions to complex problems.",
    "Throughout the course, you will learn how to use the principles of design thinking to understand user needs, identify opportunities, and prototype and test new solutions. You will also gain a deep understanding of the design thinking process and how it can be applied to a range of industries and fields",
  ],

  whatYouWillLearn: [
    "In this course, you will learn how to design, develop, and deploy web and mobile applications from scratch. You will gain hands-on experience with popular programming languages, frameworks, and tools used for building modern web and mobile applications. The course will cover the latest front-end technologies such as HTML, CSS, and JavaScript, as well as popular back-end programming languages like Python and Ruby. You will also learn how to build responsive, mobile-first designs that work seamlessly across devices and platforms.",
    "Through a series of practical projects and real-world examples, you will learn how to create dynamic and interactive user interfaces, integrate third-party APIs, implement user authentication and authorization, and deploy your applications to the cloud.",
    "Whether you are an aspiring web or mobile application developer or a seasoned programmer looking to expand your skillset, this course will provide you with the knowledge and practical skills needed to succeed in today's fast-paced technology industry.",
    "Join us and take the first step towards becoming a successful web and mobile application developer!",
  ],

  appliedLearningProject:
    "What is an applied learning project? It's a project-based learning experience that allows students to take an active role in solving real-world problems. Through our program, students will work closely with faculty, industry experts, and community partners to identify a problem or challenge, develop a plan of action, and implement a solution.",

  benefitsForStudents: [
    {
      headerTxt: "Practical experience :",
      contentTxt:
        "Students will gain practical, hands-on experience that is directly applicable to their future careers.",
    },
    {
      headerTxt: "Problem-solving skills :",
      contentTxt:
        "Through our program, students will develop critical thinking and problem-solving skills that will help them succeed in their future careers.",
    },
    {
      headerTxt: "Networking opportunities :",
      contentTxt:
        "Our program offers students the opportunity to network with industry experts and community partners, building valuable connections for their future careers",
    },
    {
      headerTxt: "Personal growth :",
      contentTxt:
        "Through our program, students will develop their interpersonal skills, teamwork abilities, and leadership capabilities, all of which are essential for success in any career.",
    },
    {
      headerTxt: "Resume building :",
      contentTxt:
        "Participating in an applied learning project is a valuable addition to any student's resume, demonstrating their commitment to experiential learning and problem-solving.",
    },
  ],

  startLearningToday: [
    "Comprehensive knowledge",
    "Hands-on experience",
    "In-demand skills",
    "Creative Freedom",
    "Flexibility",
    "Community",
  ],
};

const index = () => {
  return (
    <SiteLayout>
      <div className="home_middle_sec top_margin_comn pt-5 align-items-center">
        <div className="container px-3 row pb-3 pb-md-5">
          <div className="col-12 col-md-6">
            <HeaderEnrollNow
              headerText={data.boxDetails.title}
              subtitle={data.boxDetails.text}
              rating={data.boxDetails.rating}
              totalRating={data.boxDetails.totalRating}
              enroll={data.boxDetails.enroll}
              started={data.boxDetails.started}
            />
          </div>
          <div className="col-12 col-md-6 header_colorfulBox_container py-3">
            {data.colorfulBoxContent.map((item) => (
              <ColorfulBox item={item} />
            ))}
          </div>
        </div>
        <BuildJob jobTitles={data.jobTitles} jobTitlesBox={data.jobTitlesBox} />
        <Skills skills={data.skills} />
        <Certificate />
        <AboutProfessionalCertificate />
        <WhatyouWillLearn whatYouWillLearn={data.whatYouWillLearn} />
        <AppliedLearningProject />
      </div>
    </SiteLayout>
  );
};

export default index;

const AboutProfessionalCertificate = () => {
  return (
    <div className="container">
      <div className="row justify-content-center py-5">
        <span className="headerText">About this Professional Certificate</span>
      </div>
      <div className="row justify-content-center pb-5">
        <p className="contentText text-center">
          In this course, you will learn how to{" "}
          <span className="highlight_text">
            {" "}
            design, develop, and deploy web and mobile applications{" "}
          </span>{" "}
          from scratch. You will gain hands-on experience with popular
          programming languages, frameworks, and tools used for building modern
          web and mobile applications. The course will cover the latest
          front-end technologies such as
          <span className="highlight_text"> HTML, CSS, and JavaScript,</span> as
          well as popular back-end programming languages like
          <span className="highlight_text">Python and Ruby.</span> You will also
          learn how to build responsive, mobile-first designs that work
          seamlessly across devices and platforms.
        </p>
      </div>
    </div>
  );
};
